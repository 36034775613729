import React, { Component } from "react"
import { Map, Marker, Popup, TileLayer } from "react-leaflet"

export default class MyMap extends Component {
  render() {
    const { options } = this.props
    const position = [56.626279, 47.880196]
    if (typeof window !== "undefined") {
      return (
        <>
          <Map
            {...options}
            center={position}
            zoom={17}
            style={{ height: `25rem` }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}>
              <Popup>
                ФС-ТЕХНОЛГИИ
                <br />
                Здравствуйте
              </Popup>
            </Marker>
          </Map>
        </>
      )
    }
    return null
  }
}
